import React from "react";
import { Img, Text, Heading } from "../index.js";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="mt-[71px] self-stretch">
      {/* footer section */}
      <div className="flex justify-center border-t border-solid border-gray-200 py-[60px] md:py-5">
        <div className="container-xs flex md:p-5">
          <div className="flex w-[88%] items-center justify-between gap-5 md:w-full md:flex-col">
            <div className="flex w-[29%] flex-col gap-[19px] md:w-full">
              <Link to="/">
                <Img
                  src="/images/1.png"
                  alt="footer logo"
                  className="size-10 w-[120px] object-contain"
                />
              </Link>
              <ul className="flex flex-col gap-[11px]">
                <li>
                  <a href="#">
                    <div className="flex items-center gap-2 self-start">
                      <Img
                        src="/images/facebookLogo.svg"
                        alt="facebook icon"
                        className="h-[20px] w-[20px]"
                      />
                      <Text as="p">Facebook</Text>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div className="flex items-center gap-2 self-start">
                      <Img
                        src="/images/youtubeLogo.svg"
                        alt="youtube icon"
                        className="h-[19px] w-[19px]"
                      />
                      <Text as="p">Youtube</Text>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div className="flex items-center gap-2 self-start">
                      <Img
                        src="/images/telegramLogo.png"
                        alt="telegram icon"
                        className="h-[20px] w-[20px] self-start object-cover"
                      />
                      <Text as="p">Telegram</Text>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div className="flex items-center gap-2 self-start">
                      <Img
                        src="/images/twitterLogo.svg"
                        alt="twitter icon"
                        className="h-[20px] w-[20px]"
                      />
                      <Text as="p">Twitter</Text>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-[19px]">
              <Heading as="h6">Getting started</Heading>
              <ul className="flex flex-col gap-[11px]">
                <li>
                  <a href="#">
                    <Text as="p">Release Notes</Text>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Text as="p">Upgrade Guide</Text>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Text as="p">Browser Support</Text>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Text as="p">Dark Mode</Text>
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-[19px]">
              <Heading as="h6">Explore</Heading>
              <ul className="flex flex-col gap-[11px]">
                <li>
                  <a href="#">
                    <Text as="p">Prototyping</Text>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Text as="p">Design systems</Text>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Text as="p">Pricing</Text>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Text as="p">Security</Text>
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex flex-col items-start gap-[19px]">
              <Heading as="h6">Community</Heading>
              <ul className="flex flex-col items-start gap-[11px]">
                <li>
                  <a href="#">
                    <Text as="p">Discussion Forums</Text>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Text as="p">Code of Conduct</Text>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Text as="p">Contributing</Text>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Text as="p">API Reference</Text>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center border-t border-solid border-gray-200_01 py-8 sm:py-5">
        <div className="container-xs flex items-center justify-between gap-5 md:p-5 sm:flex-col">
          <a href="#">
            <div className="flex">
              <Text as="p">The Zaik &copy; 2025</Text>
            </div>
          </a>
          <div className="flex items-center  justify-center gap-1 sm:w-full">
            <Img
              src="/images/paypal-Logo.svg"
              alt="paypal icon"
              className="size-10"
            />

            <Img
              src="/images/stripe.svg"
              alt="vector icon"
              className="size-10 object-contain rounded-lg"
            />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
